import { useState } from 'react'
import Loading from "components/common/Loading";

export default function Button(props) {

   const {id, label, onClick, isDisabled, isLoading} = props;

   const handleOnClick = async (e) => {
      await onClick();
   }
   
   return ( 
      <> 
         <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
            id={id}
            disabled={isDisabled}
            type="button"
            className={`bg-indigo-600 inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            onClick={handleOnClick}
            >
               {isLoading ? <Loading showLogo={false} size="h-5 w-5" /> : label}
            </button>
         </div>
         
         
      </>
   );

}