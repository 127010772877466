import { useContext } from "react";
import { getApp } from "@firebase/app";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  addDoc,
  collection,
  Timestamp,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  updateDoc,
} from "@firebase/firestore";
import { storage } from "../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "@firebase/storage";
import {
  getStripePayments,
  createCheckoutSession,
  getCurrentUserSubscriptions,
  getCurrentUserSubscription,
  getProducts,
} from "@stripe/firestore-stripe-payments";
import { getFunctions, httpsCallable } from "@firebase/functions";
import * as Constants from "util/constants";

const createError = (title) => {
  return {
    success: false,
    error: {
      title: title ? title : "Something went wrong",
    },
  };
};

export const featureCheck = async (user, feature) => {
  const featureMap = Constants.SECURITY_FEATURE_MAP;
  await user.getIdToken(true);
  const role = await getUserRole(user);

  if (!featureMap[feature]) {
    return false;
  }

  return featureMap[feature].indexOf(role) > -1;
};

export const getUser = async (user) => {
  try {
    const userDoc = await getDoc(doc(db, `sayso`, user.uid));
    return userDoc.data();
  } catch (e) {
    console.log("Unable to fetch user information");
  }
}

export const getWalkthroughFlag = async (user) => {
  try {
    const userRef = collection(db, `sayso`);
    const userQuery = query(
      userRef,
      where("__name__", "==", user.uid),
      limit(1)
    );

    const snapshot = await getDocs(userQuery);
    if (snapshot.size > 0) {
      return snapshot.docs[0].data().walkthrough;
    } else {
      return false; // TODO: User not fully loaded or error
    }
  } catch (e) {
    if (e.code == "permission-denied") {
      await user.getIdToken(true);
      throw e;
    }
    console.log("code ", e.code);
    console.log("name ", e.name);

    console.log("message ", e.message);
    console.log("Unable to fetch user information");
  }
};

export const getPrompt = async (user) => {
  try {
    const promptRef = collection(db, `sayso/${user.uid}/prompts`);
    const q = query(promptRef, orderBy("assigned_date", "desc"), limit(1));
    const prompts = await getDocs(q);
    if (prompts.size > 0) {
      const doc = {
        ...prompts.docs[0].data(),
        id: prompts.docs[0]?.id,
      };
      return doc;
    }
    return {};
  } catch (e) {
    console.log(e);
    console.log("Unable to retrieve prompt");
    throw e;
  }
};

export const getPromptResponseHistory = async (user, prompt_id) => {
  try {
    const uid = user.uid; // Get the current user's UID
    const responsesRef = collection(db, `sayso/${user.uid}/responses`);

    // Query by prompt.id and uid
    const q2 = query(
      responsesRef,
      where("prompt.id", "==", prompt_id),
      orderBy("timestamp", "desc")
    );

    const querySnapshot = await getDocs(q2);
    const response = [];
    querySnapshot.forEach((doc) => {
      // Add individual doc to array, with some additional fields
      const data = doc.data();
      response.push({
        id: doc.id,
        timestamp: data.timestamp,
        mood: data.mood,
        isText: data.content?.length > 0,
        isFile: !(data.fileUrl === null),
        isAudio: !(data.audioUrl === null),
        type: "response",
      });
    });
    return response;
  } catch (e) {
    console.log("Failed to generate user feed");
  }
};

export const setWalkthrough = async (user, flag) => {
  // const userRef = collection(db,`sayso`);
  // const userQuery = query(userRef, where("__name__", "==", user.uid), limit(1))

  const userDoc = await (await getDoc(doc(db, `sayso`, user.uid))).data();
  console.log(userDoc);
  userDoc.walkthrough = flag;

  await setDoc(doc(db, `sayso`, user.uid), userDoc);
  console.log("Written successfully");
};

export const setResponse = async (user, document) => {
  // UPLOAD RESPONSE DOC
  const doc = await addDoc(
    collection(db, `sayso/${user.uid}/responses`),
    document
  );
  console.log(doc.id);
  return doc.id;
};

export const setAudio = async (user, timestamp, audio) => {
  const sr = ref(storage, `sayso/${user.uid}/${timestamp}/ssp-app-recording`);
  await uploadBytes(sr, audio);
  return sr;
};

export const setFile = async (user, timestamp, file) => {
  const storageRef = ref(
    storage,
    `sayso/${user.uid}/${timestamp}/${file.name}`
  );
  await uploadBytes(storageRef, file);
  return storageRef;
};

export const submitResponse = async (user, doc, audio, file) => {
  try {
    //upload file to firebase storage if one is provided
    const timestamp = serverTimestamp();
    doc.timestamp = timestamp;

    const pathTimestamp = Timestamp.fromDate(new Date()).toDate().toISOString();

    // UPLOAD AUDIO BLOB
    if (audio != null) {
      const sr = await setAudio(user, pathTimestamp, audio);
      doc.audioPath = sr.fullPath;
      doc.audioUrl = await getDownloadURL(sr);
      console.log("Audio Upload Done!");
    }

    // UPLOAD FILES
    if (file != null) {
      const sr = await setFile(user, pathTimestamp, file);
      doc.filePaths = sr.fullPath;
      doc.fileUrl = await getDownloadURL(sr);
      console.log("File Upload Done!");
    }

    // UPLOAD RESPONSE DOC
    const docId = await setResponse(user, doc);

    return {
      success: true,
      title: "Response submitted successfully",
      meta: { docId: docId },
    };
  } catch (ex) {
    console.log("Failed to create response");
    return {
      success: false,
      error: {
        title: "Failed to upload response",
        messages: ["We couldn't submit your response! Please try again!"],
      },
    };
  }
};

export const getResponses = async (user) => {
  try {
    const q = query(
      collection(db, `sayso/${user.uid}/responses`),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);
    const response = [];
    querySnapshot.forEach((doc) => {
      //Add individual doc to array
      response.push({ id: doc.id, ...doc.data() });
    });
    return response;
  } catch (ex) {
    console.log("Failed to get responses");
    return createError("Failed to get response");
  }
};

export const setHelp = async (user, doc) => {
  try {
    doc.uid = user.uid;
    doc.timestamp = serverTimestamp();
    await addDoc(collection(db, `sayso/${user.uid}/help`), doc);
    console.log("Submitted case successfully");
    return { success: true, title: "Help submitted successfully" };
  } catch (ex) {
    console.log("Failed to create case");
    return createError();
  }
};

export const setGeneratePrompt = async (user) => {
  try {
    // Create request document
    const doc = {
      requestDate: serverTimestamp(),
      uid: user.uid,
    };
    // UPLOAD RESPONSE DOC
    await addDoc(collection(db, `sayso/${user.uid}/prompt_requests`), doc);
    return { success: true, title: "New prompt is being generated" };
  } catch (ex) {
    if (ex.code == "permission-denied") {
      return createError("premium");
    }
    console.log("Failed to create case");
    return createError();
  }
};

export const setPromptRating = async (user, rating, responseId) => {
  const responseDocRef = doc(db, `sayso/${user.uid}/responses`, responseId);
  await updateDoc(responseDocRef, {
    promptRating: rating,
  });

  console.log("Written successfully");
};

export const setJoiningReason = async (user, reasons) => {
  const userDocRef = doc(db, `sayso/`, `${user.uid}`);
  await updateDoc(userDocRef, {
    reasons: reasons,
  });
};

export const getNotificationsForUser = async (user) => {
  try {
    const q = query(
      collection(db, `sayso/${user.uid}/notifications`),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);
    const notifications = [];
    querySnapshot.forEach((doc) => {
      //Add individual doc to array
      notifications.push({ id: doc.id, ...doc.data() });
    });
    return notifications;
  } catch (ex) {
    console.log("Failed to get notifications", ex);
    return createError("Failed to get notifications");
  }
};

export const clearNotificationsForUser = async (user, notifications) => {
  try {
    //Setup notification doc update
    //Submit to DB
  } catch (ex) {
    console.log("Failed to clear notifications", ex);
    return createError("Failed to clear notifications");
  }
};

export const getUserMetrics = async (uid) => {
  try {
    // Get first metric from user at sayso/userid/metrics
    const q = query(
      collection(db, `sayso/${uid}/metrics`),
      orderBy("system.createdAt", "desc"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    const metrics = [];
    querySnapshot.forEach((doc) => {
      //Add individual doc to array
      metrics.push({ id: doc.id, ...doc.data() });
    });
    return metrics[0];
  } catch (ex) {
    console.log("Failed to get metrics", ex);
    return createError("Failed to get metrics");
  }
};

export const setUserNewPromptEmailPreference = async (user, preference) => {
  //this can be done much better, but lazy for now
  try {
    const userDocRef = doc(db, `sayso/`, `${user.uid}`);
    await updateDoc(userDocRef, {
      preferences: {
        preference_email_new_prompt: preference,
      }
    });
    return { success: true, title: "Preferences updated successfully" };
  } catch (ex) {
    console.log("Failed to update preferences", ex);
    return createError("Failed to update preferences");
  }
};

export const setUser = async (user, data) => {
  try {
    const userDocRef = doc(db, `sayso/`, `${user.uid}`);
    await setDoc(userDocRef, data, { merge: true });
    return { success: true, title: "User updated successfully" };
  } catch (ex) {
    console.log("Failed to update user", ex);
    return createError("Failed to update user");
  }

};

// ---------------------- PAYMENT ---------------------  //

const getPayments = () => {
  const app = getApp();
  const payments = getStripePayments(app, {
    productsCollection: "stripe_products",
    customersCollection: "stripe_customers",
  });
  return payments;
};

const transformPrice = (priceArray) => {
  const price = { annually: null, monthly: null };
  for (var i = 0; i < priceArray.length; i++) {
    if (priceArray[i].interval == "year") {
      price.annually = {
        id: priceArray[i].id,
        value: `$${priceArray[i].unit_amount / 100}`,
      };
    } else if (priceArray[i].interval == "month") {
      price.monthly = {
        id: priceArray[i].id,
        value: `$${priceArray[i].unit_amount / 100}`,
      };
    }
  }
  return price;
};

const transformFeatureList = (name) => {
  if (name === Constants.PRODUCT_BASIC) {
    return Constants.PRODUCT_BASIC_FEATURES;
  } else if (name === Constants.PRODUCT_PREMIUM) {
    return Constants.PRODUCT_PREMIUM_FEATURES;
  } else if (name === Constants.PRODUCT_GROUP) {
    return Constants.PRODUCT_GROUP_FEATURES;
  }

  return [];
};

const transformProduct = (p) => {
  return {
    name: p.name,
    id: p.id,
    price: transformPrice(p.prices),
    description: p.description,
    features: transformFeatureList(p.name),
    role: p.role,
  };
};

const transformSubscription = (s) => {
  if (s.role == Constants.SECURITY_LEVEL_2) {
    s.name = "Hardcover Subscription";
    s.description = "You have all the amazing features available!";
    s.active = true;
  } else if (s.role == Constants.SECURITY_LEVEL_1) {
    s.name = "Paperback Subscription";
    s.description = "You have some of the cool features, consider upgrading!";
    s.active = true;
  }
  return s;
};

export const getUserRole = async (user) => {
  await user.getIdToken(true);
  const token = await user.getIdTokenResult();
  return token.claims.stripeRole;
};

export const getProductList = async (user) => {
  const payments = getPayments();
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });

  //transform into appropriate format
  const result = [];

  for (var i = 0; i < products.length; i++) {
    var p = transformProduct(products[i]);

    //forcing ordering (which is important for upsell)
    result[0] = p.role == Constants.SECURITY_LEVEL_1 ? p : result[0];
    result[1] = p.role == Constants.SECURITY_LEVEL_2 ? p : result[1];
  }

  //enrich products, show which one is active, lazily
  const role = await getUserRole(user);
  result[0].active =
    role == Constants.SECURITY_LEVEL_1 || role == Constants.SECURITY_LEVEL_2;
  result[1].active =
    role == Constants.SECURITY_LEVEL_1 || role == Constants.SECURITY_LEVEL_2;
  console.log(result);
  console.log(role);
  return result;
};

export const getPublicProducts = async () => {
  const payments = getPayments();
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });

  //transform into appropriate format
  const result = [];

  for (var i = 0; i < products.length; i++) {
    var p = transformProduct(products[i]);

    //forcing ordering (which is important for upsell)
    switch (p.name) {
      case Constants.PRODUCT_BASIC:
        p.name = "Paperback";
        p.mostPopular = false;
        p.href = "/login";
        result[0] = p;
        break;
      case Constants.PRODUCT_PREMIUM:
        p.name = "Hardcover";
        p.mostPopular = true;
        p.href = "/login";
        result[1] = p;
        break;
      case Constants.PRODUCT_GROUP:
        p.name = "Library";
        p.mostPopular = false;
        p.contact = true;
        p.href = `mailto:${Constants.SAYSO_EMAIL}`;
        result[2] = p;
        break;
      default:
        //do nothing
        break;
    }
  }
  return result;
};

export const getUserSubscription = async (user) => {
  const subscriptions = await getCurrentUserSubscriptions(getPayments(), {
    status: ["active", "trialing"],
  });

  if (subscriptions.length > 0) {
    return transformSubscription(subscriptions[0]);
  }

  return { name: "Free Plan", description: "", active: false };
};

export const getCustomerPortalURL = async () => {
  try {
    const functions = getFunctions();
    functions.region = "australia-southeast1"; // TODO: need to make this better
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({ returnUrl: window.location.href });
    return data;
  } catch (e) {
    console.log(e);
    return createError("Unable to create customer portal URL");
  }
};

export const createCustomerCheckoutSession = async (priceId) => {
  const session = await createCheckoutSession(getPayments(), {
    price: priceId,
    successUrl: window.location.origin,
    cancelUrl: window.location.origin,
    billing_address_collection: "auto",
    allow_promotion_codes: true,
    payment_method_collection: "if_required",
    consent_collection: {
      terms_of_service: "required",
    },
    // trial_from_plan: true,
  });
  return session;
}
