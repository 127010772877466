
import { Fragment, useState, useEffect } from 'react'
import {UserAuth} from '../context/AuthContext'
import Checkbox from '../components/common/Checkbox'
import { getUser, setUser } from "../services/api.js";
import Loading from "components/common/Loading";
import Button from "components/common/Button";
import Modal from "components/modal/SuccessModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getInputClassNames(){
  return "block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
}



export default function ProfileView() {
  const {user} = UserAuth();

  const [userDoc, setUserDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setNewPromptEmailPreference = async (preference) => {
    //update the preference in the user doc state only
    setUserDoc((prevState) => {
      return {
        ...prevState,
        preferences: {
          ...prevState.preferences,
          preference_email_new_prompt: preference,
        },
      };
    });

    
  };

  const save = async () => {
    setIsSaving(true);
    setUser(user, userDoc).then(() => {
      setIsModalOpen(true);
    }
    ).catch((e) => {
      console.log(`Could not save user document: ${e}`);
    }).finally(() => {
      setIsSaving(false);
    }
    );
  }

  useEffect(() => {
      getUser(user)
        .then((doc) => {
          setUserDoc(doc);
          console.log(doc)
        })
        .catch((e) => {
          console.log(`Could not retrieve user document: ${e}`);
          //TODO: Handle error
        }).finally(() => {
          setLoading(false);
        }
      );
    }, []);

  return (
    <>
      <Modal title="Success" message="Your changes have been saved." isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>
      <div>
        <div className="xl:pl-72">
          <main>
            <div>
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 lg:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-500">Personal Information</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your details to ensure you get the most out of SaySo.
                  </p>
                </div>

                <form className="md:col-span-2">
                  {loading ? 
                    <>
                      <div className="flex justify-center items-center mt-4 w-full">
                        <Loading size="h-16 w-16" showLogo={false} />
                      </div>
                    </>
                    :
                    <>
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      
                      {user?.photoURL && <img
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                        src={user?.photoURL}
                        alt=""
                      />
                      }
                      {
                        !user?.photoURL && 
                        <span className="inline-flex items-center justify-center h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover">
                        <span className="text-sm font-medium leading-none text-white">No Picture</span>
                        </span> 

                      }
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className={getInputClassNames()}
                          value={user.email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <h2 className="text-base font-semibold leading-7 text-gray-500">Email Preferences</h2>
                      
                      <div className="mt-4">
                        <Checkbox id='email-preference' name='email-preferences' label='New Prompts' description='Get notified when you have a new prompt assigned to you.' checked={userDoc?.preferences?.preference_email_new_prompt} onChange={setNewPromptEmailPreference}/>
                        
                      </div>
                    </div>
                    <Button id="submit" label="Save" onClick={save} isDisabled={isSaving} isLoading={isSaving}/>
                  </div> 
                  </>
                  }
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
