export default function Loading(props) {
  const size = props.size || "h-16 w-16";
  //show logo true by default, if false, hide

  const showLogo = props.showLogo === true

  return (
    <>
      <div className="relative inline-block" role="status">
        <div
          className={`${size} animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        ></div>

        {showLogo && (
          <img
            src="/sayso-logo.png"
            alt="SaySo Logo"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse"
            style={{ height: "50%", width: "50%" }}
          />
        )}

        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </>
  );
}
